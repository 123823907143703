import dentalUpPortfolioImage from '../assets/dentalUpPortfolio.jpg';
import cerrajeriaImage from '../assets/cerrajeria.jpg';
import sintegralesImage from '../assets/sintegrales.jpg';
import portfolioImage from '../assets/portfolio.jpg';
import mernImage from '../assets/mern.jpg';

export const projectList = [

    {
        name: 'Soluciones Integrales',
      image: sintegralesImage,
       skills: 'PHP, HTML, CSS',
       link: 'https://sintegrales.com.uy',
       github: ''
    },
    {
        name: 'Cerrajeria CH',
        image: cerrajeriaImage,
       skills: 'Wordpress',
       link: 'https://cerrajeriach.dev.uy',
       github: ''
    },
    {
        name: 'Dental Up',
       image: dentalUpPortfolioImage,
       skills: 'Wordpress',
       link: 'https://dentalup.uy',
       github: ''
    },
    {
        name: 'Portfolio',
        image: portfolioImage,
       skills: 'React JS, Node JS',
       link: '',
       github: 'https://sintegrales.com.uy'
    },{
        name: 'CRUD MERN',
        image: mernImage,
       skills: 'Mysql, Express, React JS, Node JS',
       link: '',
       github: 'https://sintegrales.com.uy'
    }
]